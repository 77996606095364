export function getImageThumbnail(url, thumbnailWidth) {
  if (!url) return null;
  let resultUrl = `${url}`;
  const findingDimensionsRegex = /w(\d+)h(\d+)?/g;
  const match = url.match(findingDimensionsRegex);
  if (match?.length > 0) {
    resultUrl = url.replace(match[0], thumbnailWidth);
  }
  return resultUrl;
}

import mobilePointing from "../assets/images/mobile_pointing_optimized.png";
import thumbsUp from "../assets/images/thumbs_up_optimized.png";
import spaceChair from "../assets/images/space_chair_optimized.png";
import drink from "../assets/images/drink_optimized.png";
import reaching from "../assets/images/reaching_optimized.png";
import kiosk from "../assets/images/kiosk_optimized.png";
import sittingSide from "../assets/images/sitting_side_optimized.png";
import goldClass from "../assets/images/kosmik-gc.png";

export const kosmikTheme = (variant) => {
  return (
    {
      goldClass: "theme-gold",
    }?.[variant] ?? ""
  );
};

export const kosmikVariant = (variant) => {
  return (
    {
      mobilePointing,
      thumbsUp,
      spaceChair,
      goldClass,
      drink,
      reaching,
      kiosk,
      sittingSide,
    }?.[variant] ?? mobilePointing
  );
};

export const kosmikVariantBubble = (variant) => {
  switch (variant) {
    case "mobilePointing":
    default:
      return {
        svgLineViewBox: "0 0 215 73",
        svgLinePath:
          "M185.2 2.9 215 72.5l-1 .4-29.5-69H5.5a2.7 2.7 0 1 1 0-1h179.7Z",
        contentClasses:
          "w-[17vw] lg:w-[16vw] xl:w-[15vw] 2xl:w-[14vw] 3xl:w-[13vw]",
        wrapperClasses: "gap-x-[10vw]",
        imagePosition: "sm:-top-4 xl:-top-2 3xl:top-0",
        imageWidth:
          "w-[60vw] xs:w-[50vw] sm:w-[35vw] lg:w-[30vw] xl:w-[25vw] 2xl:w-[22vw] 3xl:w-[18vw]",
      };
    case "thumbsUp":
      return {
        svgLineViewBox: "0 0 215 73",
        svgLinePath:
          "M185.2 2.9 215 72.5l-1 .4-29.5-69H5.5a2.7 2.7 0 1 1 0-1h179.7Z",
        contentClasses:
          "w-[17vw] lg:w-[15vw] xl:w-[13vw] 2xl:w-[15vw] 3xl:w-[16vw]",
        wrapperClasses: "gap-x-[4vw]",
        imagePosition: "sm:-top-2 xl:top-0",
        imageWidth:
          "w-[60vw] xs:w-[50vw] sm:w-[35vw] lg:w-[30vw] xl:w-[25vw] 2xl:w-[31vw]",
      };
    case "spaceChair":
      return {
        svgLineViewBox: "0 0 195 100",
        svgLinePath:
          "m152.8 2.9 41.3 96-1 .4L152 4H5.5a2.7 2.7 0 1 1 0-1h147.3Z",
        contentClasses:
          "w-[16vw] lg:w-[15vw] xl:w-[13vw] 2xl:w-[14vw] 3xl:w-[12vw]",
        wrapperClasses: "gap-x-[6.7vw]",
        imagePosition: "sm:-top-4 xl:-top-2 3xl:top-0",
        imageWidth:
          "w-[60vw] xs:w-[50vw] sm:w-[35vw] lg:w-[30vw] xl:w-[25vw] 2xl:w-[30vw] 3xl:w-[22vw]",
      };
    case "goldClass":
      return {
        svgLineViewBox: "0 0 195 100",
        svgLinePath:
          "m152.8 2.9 41.3 96-1 .4L152 4H5.5a2.7 2.7 0 1 1 0-1h147.3Z",
        contentClasses:
          "w-[20vw] sm:w-[23vw] lg:w-[18vw] xl:w-[18vw] 2xl:w-[18vw] 3xl:w-[14vw]",
        wrapperClasses: "gap-x-[5.7vw]",
        imagePosition: "sm:-top-4 xl:-top-2 3xl:top-0",
        imageWidth:
          "w-[90vw] xs:w-[80vw] sm:w-[40vw] lg:w-[30vw] xl:w-[30vw] 2xl:w-[30vw] 3xl:w-[22vw]",
      };
    case "drink":
      return {
        svgLineViewBox: "0 0 215 73",
        svgLinePath:
          "m116.9 2.9 16.8 38.8-1 .4-16.5-38.2H5.5a2.7 2.7 0 1 1 0-1h111.4Z",
        contentClasses:
          "w-[21.5vw] lg:w-[18vw] xl:w-[15vw] 2xl:w-[18vw] 3xl:w-[14vw]",
        wrapperClasses: "gap-x-[1vw]",
        buttonClasses: "2xs:mb-4 xs:mb-16 sm:mb-0",
        imagePosition: "sm:-top-2 xl:-top-2 3xl:top-0",
        imageWidth:
          "w-[60vw] xs:w-[50vw] sm:w-[35vw] lg:w-[30vw] xl:w-[25vw] 2xl:w-[30vw] 3xl:w-[23vw]",
      };
    case "reaching":
      return {
        svgLineViewBox: "0 0 215 73",
        svgLinePath:
          "m116.9 2.9 16.8 38.8-1 .4-16.5-38.2H5.5a2.7 2.7 0 1 1 0-1h111.4Z",
        contentClasses:
          "w-[20vw] lg:w-[18vw] xl:w-[15vw] 2xl:w-[18vw] 3xl:w-[14vw]",
        wrapperClasses: "gap-x-[4vw]",
        imagePosition: "sm:-top-4",
        imageWidth:
          "w-[60vw] xs:w-[50vw] sm:w-[35vw] lg:w-[30vw] xl:w-[25vw] 2xl:w-[30vw] 3xl:w-[23vw]",
      };
    case "kiosk":
      return {
        svgLineViewBox: "0 0 247 44",
        svgLinePath:
          "m228.6 2.9 16.9 38.8-1 .4-16.6-38.2H5.5a2.7 2.7 0 1 1 0-1h223.1Z",
        contentClasses:
          "w-[26vw] lg:w-[22vw] xl:w-[18vw] 2xl:w-[16vw] 3xl:w-[13vw]",
        wrapperClasses: "gap-x-[2vw]",
        imagePosition: "sm:-top-4 xl:-top-2",
        imageWidth:
          "w-[60vw] xs:w-[50vw] sm:w-[35vw] lg:w-[30vw] xl:w-[25vw] 2xl:w-[22vw] 3xl:w-[18vw]",
      };
    case "sittingSide":
      return {
        svgLineViewBox: "0 0 116 91",
        svgLinePath:
          "m72.4 2.9 41.2 86.9-1 .4-41-86.3H5.6a2.7 2.7 0 1 1 0-1h66.9Z",
        contentClasses:
          "w-[14vw] lg:w-[11vw] xl:w-[9vw] 2xl:w-[11vw] 3xl:w-[8vw]",
        wrapperClasses: "gap-x-[1vw]",
        imagePosition: "sm:-top-3 xl:-top-4 3xl:-top-6",
        imageWidth:
          "w-[60vw] xs:w-[50vw] sm:w-[35vw] lg:w-[30vw] xl:w-[25vw] 2xl:w-[30vw] 3xl:w-[23vw]",
      };
  }
};
